._35nH5 {
  transition: opacity 0.5s ease;
  position: relative;
}

._tmp4k {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

._rhSXE {
  transition: left 0.3s ease, right 0.3s ease, top 0.3s ease, bottom 0.3s ease,
    transform 0.3s ease;
}

._3ZSle {
  transition: transform 0.4s cubic-bezier(0.47, 1.64, 0.41, 0.8),
    left 0.4s cubic-bezier(0.47, 1.64, 0.41, 0.8),
    right 0.4s cubic-bezier(0.47, 1.64, 0.41, 0.8),
    top 0.4s cubic-bezier(0.47, 1.64, 0.41, 0.8),
    bottom 0.4s cubic-bezier(0.47, 1.64, 0.41, 0.8);
}

._1CAfA {
  transition: transform 0.4s cubic-bezier(0, 0.55, 0.45, 1),
    left 0.4s cubic-bezier(0, 0.55, 0.45, 1),
    right 0.4s cubic-bezier(0, 0.55, 0.45, 1),
    top 0.4s cubic-bezier(0, 0.55, 0.45, 1),
    bottom 0.4s cubic-bezier(0, 0.55, 0.45, 1);
}
